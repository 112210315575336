import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import { useTranslation } from "react-i18next";

const MainPolicies = () => {
    return (
       <>
          <PageHelmet pageTitle="Company Policies" />
 
          <HomeOneHeader/>
          <CommonPageHeader title="Company Policies" subtitle="Company Policies" />
 
          <section className="policy__area pt-115 pb-75">
             <div className="container">
                <div className="row">
                   <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                      <div className="section__title section__title-3 mb-85 text-center">
                         <span>Privacy & Company Policies</span>
                         <h2>Our Commitment to Your Privacy</h2>
                      </div>
                      <div className="text-center">
                         <br/>
                         <h3>Introduction</h3>
                         <br/>
                         <p>We are committed to protecting your privacy and ensuring the security of your personal information. This policy outlines how we collect, use, and safeguard your data.</p>
                         
                         <br/>
                         <h4>Data Collection</h4>
                         <p>We collect personal data that you provide to us, such as your name, email address, and any other information required for our services.</p>
                         
                         <br/>
                         <h4>Use of Data</h4>
                         <p>Your data is used to provide and improve our services, communicate updates, and ensure a personalized experience.</p>
                         
                         <br/>
                         <h4>Data Protection</h4>
                         <p>We implement strict security measures to protect your personal information from unauthorized access, alteration, or disclosure.</p>
                         
                         <br/>
                         <h4>Third-Party Sharing</h4>
                         <p>We do not sell or share your data with third parties except when required by law or with your explicit consent.</p>
                         
                         <br/>
                         <h4>Cookies and Tracking</h4>
                         <p>Our website may use cookies to enhance your experience. You can manage your cookie preferences through your browser settings.</p>
                         
                         <br/>
                         <h4>Contact</h4>
                         <p>If you have any questions about our privacy policy, feel free to contact us at <b>letstalk@invokeapp.io</b></p>
                      </div>
                   </div>
                </div>
             </div>
          </section>
 
          <CommonCtaArea/>
          <Footer/>
       </>
    );
 };
 
 export default MainPolicies;
 